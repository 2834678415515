import React, {Component} from "react";
import { Button, Form, FormGroup, Input, } from 'reactstrap';
import { Redirect } from 'react-router'

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            country: '',
            username: '',
            password: '',
            redirect: false,
        }
    }

    onFormSubmit() {
        this.validateForm();
    }

    validateForm() {
        if(this.state.country === ''){
            this.props.updateErrorMessage("Please select a country");
            return
        }
        if(this.state.username === ''){
            this.props.updateErrorMessage("Please fill in username");
            return
        }
        if(this.state.password === ''){
            this.props.updateErrorMessage("Please fill in password");
            return
        }
        this.postData();
    }

    postData() {
        let data = {
            "country": this.state.country,
            "username": this.state.username,
            "password": this.state.password
        };
        fetch('https://intralplugins.com/Viasat/CustomerAppBack/public/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(function(response){
            return response.json();
        }).then(data => {
            this.authenticateUser(data);
        }).catch(function(err){
            console.log(err)
        });
    }

    authenticateUser(data) {
        if (data.status === "success"){
            this.props.updateLoggedInStatus();
            this.setState({redirect: true });
            localStorage.setItem('userFullName', data.userFullName);
            localStorage.setItem('country', this.state.country);
        }
        else {
            this.props.updateErrorMessage("Unsuccessful login. Please check username and/or password")
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/chooseaction'/>;
        }
        return (
            <Form className={"loginForm"}>
                <FormGroup>
                    <Input value={this.state.country}
                           onChange={e => this.setState({ country: e.target.value })}
                           type="select" name="country">
                        <option value="" defaultValue disabled hidden>Choose country</option>
                        <option>Estonia</option>
                        <option>Latvia</option>
                        <option>Lithuania</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Input value={this.state.username}
                           onChange={e => this.setState({ username: e.target.value })}
                           type="text" name="username" placeholder="Username" />
                </FormGroup>
                <FormGroup>
                    <Input value={this.state.password}
                           onChange={e => this.setState({ password: e.target.value })}
                           type="password" name="password" placeholder="Password" />
                </FormGroup>
                    <Button id="loginButton" onClick={this.onFormSubmit}>Login</Button>
            </Form>
        );
    }
}

export default LoginForm;