import React, {Component} from "react";
import {Button, CustomInput, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {withRouter} from "react-router-dom";
import LoadingIndicator from "react-loading-indicator";
import readXlsxFile from 'read-excel-file'

const BackBtn = withRouter(({ history }) => (
    <Button type='button' onClick={() => { history.push('/chooseaction') }}><FontAwesomeIcon icon="arrow-alt-circle-left" /> Back</Button>
));

class ReturnProducts extends Component {

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleEnterProdBtnClick = this.handleEnterProdBtnClick.bind(this);
        this.handleAddRowBtnClick = this.handleAddRowBtnClick.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleRemoveProdBtnClick = this.handleRemoveProdBtnClick.bind(this);
        this.handleRemoveRowBtnClick = this.handleRemoveRowBtnClick.bind(this);
        this.onSelectedProdChange = this.onSelectedProdChange.bind(this);
        this.onSelectedRowChange = this.onSelectedRowChange.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);
        this.state = {
            dropdownItems: [],
            manuallyAddedItems: [],
            scannedCode: "",
            scannedItems: [],
            chosenItem: "",
            amount: "",
            unit: "",
            notes: "",
            euroPalletsAmount: "",
            selectedRowIndex: "",
            selectedProdIndex: "",
            isLoading: true,
            pdf: ""
        }
    }

    componentDidMount() {
        this.getProducts();
    }

    addFields(products) {
        if (this.state.notes !== "") {
            let notes = {notes : this.state.notes};
            products.push(notes);
        }
        if (this.state.euroPalletsAmount !== "") {
            let euroPalletsAmount = {euroPalletsAmount : this.state.euroPalletsAmount};
            products.push(euroPalletsAmount);
        }
        return products;
    }

    addManuallyAddedProductRow() {
        let item = this.findItemByName(this.state.chosenItem);
        if (this.checkIfItemExists(this.state.manuallyAddedItems, item.productID)) {
            this.props.updateErrorMessage("Item already exists");
            return;
        }
        item.amount = this.state.amount;
        this.setState({
            manuallyAddedItems: [...this.state.manuallyAddedItems, item]
        });
    }

    addUserFullName(data) {
        let userFullName = localStorage.getItem('userFullName');
        if (Array.isArray(data)) {
            data.push(userFullName);
        }
        else {
            data = [data, userFullName]
        }
        return data;
    }

    base64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    calculateTotalAmount() {
        let totalAmount = 0;
        this.state.manuallyAddedItems.forEach(function(item){
            totalAmount += parseInt(item.amount);
        });
        this.state.scannedItems.forEach(function(item){
            totalAmount ++;
        });
        return totalAmount;
    }

    checkIfItemExists(itemsArray, productID) {
        let item = itemsArray.find(i => {
            return i.productID === productID;
        });
        return item != null;
    }

    checkIfNumeric(amount) {
        return /^\d+$/.test(amount);
    }

    checkConfirmReturnResponse(data) {
        if (data.status === "success"){
            this.setState({
                pdf: data.pdf
            });
            this.props.updateSuccessMessage("Return confirmed!");
            this.clearFields();
        }
        else {
            this.props.updateErrorMessage("Unsuccessful return. Error code: " + data.errorCode + ". Error field: "  + data.errorField)
        }
    }

    checkGetProductByEANResponse(item) {
        if (item === "outOfStock") {
            this.props.updateErrorMessage("Product found but out of stock")
        }
        else if (item.length !== 0) {
            if (this.checkIfItemExists(this.state.scannedItems, item[0].productID)) {
                this.props.updateErrorMessage("Product already exists");
                return;
            }
            let scannedItem = item[0];
            scannedItem.amount = 1;
            this.setState({
                scannedItems: [...this.state.scannedItems, scannedItem]
            });
        }
        else {
            this.props.updateErrorMessage("Product not found")
        }
    }

    clearFields() {
        this.setState({
            amount: "",
            chosenItem: "",
            scannedCode: "",
            scannedItems: [],
            manuallyAddedItems: [],
            euroPalletsAmount: "",
            notes: ""
        });
    }

    confirmReturn() {
        let products = this.state.scannedItems.concat(this.state.manuallyAddedItems);
        products = this.addFields(products);
        products = this.addUserFullName(products);

        let country = localStorage.getItem('country');

        fetch('https://intralplugins.com/Viasat/CustomerAppBack/public/confirmreturn?country=' + country, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(products),
        }).then(function(response){
            return response.json();
        }).then(data => {
            this.checkConfirmReturnResponse(data);
        }).catch(function(err){
            console.log(err)
        });
    }

    findItemByName(name) {
        return this.state.dropdownItems.find(i => {
            return i.name === name;
        });
    }

    generateDropdownProducts() {
        let options = [];
        this.state.dropdownItems.forEach(function(item){
            options.push(<option key={item.productID}>{item.name}</option>);
        });
        return options;
    }

    generateRows() {
        let options = [];
        this.state.manuallyAddedItems.forEach(function(item){
            options.push(<option key={item.productID}>{item.name} - {item.amount} {item.unitName}</option>);
        });
        return options;
    }

    generateScannedItems() {
        let options = [];
        this.state.scannedItems.forEach((item) => {
            options.push(<option key={item.productID}>{item.code2} - {item.name}</option>);
        });
        return options;
    }

    //For getting unique option key if products with duplicate productIDs are needed
    getOptionKey(productID, options) {
        let newKey = productID;
        options.forEach((option) => {
            if (newKey == option.key) {
                newKey++;
                return this.getOptionKey(newKey, options)
            }
            else {
                return newKey;
            }
        });
        return newKey;
    }

    getProductByEAN(code) {
        let country = localStorage.getItem('country');
        let body = code;
        body = this.addUserFullName(body);
        fetch('https://intralplugins.com/Viasat/CustomerAppBack/public/return?country=' + country, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then(function(response){
            return response.json();
        }).then(data => {
            this.checkGetProductByEANResponse(data);
        }).catch(function(err){
            console.log(err)
        });
    }

    getProducts() {
        let country = localStorage.getItem('country');
        fetch('https://intralplugins.com/Viasat/CustomerAppBack/public/return?country=' + country, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(function(response){
            return response.json();
        }).then(data => {
            this.setState({dropdownItems: data, isLoading : false});
        }).catch(function(err){
            console.log(err)
        });
    }

    getUnit(productName) {
        let product = this.state.dropdownItems.find(function(prod) {
            return prod.name == productName;
        });
        return product.unitName;
    }

    handleAddRowBtnClick() {
        if (this.state.chosenItem === '' || this.state.amount === '') {
            this.props.updateErrorMessage("Please fill in all fields");
            return;
        }
        if(!this.checkIfNumeric(this.state.amount)) {
            if(this.state.amount == 0) {
                this.props.updateErrorMessage("Amount can't be 0");
                return;
            }
            this.props.updateErrorMessage("Amount must be numeric");
            return;
        }
        this.addManuallyAddedProductRow();
    }

    handleFileSelect() {
        readXlsxFile(document.getElementById('importBtn').files[0]).then((rows) => {
            rows.forEach((code) => {
                this.getProductByEAN(code)
            });
        })
    }

    handleEnterProdBtnClick() {
        if (this.state.scannedCode === '') {
            this.props.updateErrorMessage("Please enter a serial number");
            return;
        }
        this.getProductByEAN(this.state.scannedCode);
    }

    handleRemoveProdBtnClick() {
        let scannedItems = [...this.state.scannedItems];
        if (this.state.selectedProdIndex !== "") {
            scannedItems.splice(this.state.selectedProdIndex, 1);
            this.setState({scannedItems: scannedItems});
            this.setState({selectedProdIndex: ""})
        }
        else {
            this.props.updateErrorMessage("No product selected");
        }
    }

    handleRemoveRowBtnClick() {
        let manuallyAddedItems = [...this.state.manuallyAddedItems];
        if (this.state.selectedRowIndex !== "") {
            manuallyAddedItems.splice(this.state.selectedRowIndex, 1);
            this.setState({manuallyAddedItems: manuallyAddedItems});
            this.setState({selectedRowIndex: ""})
        }
        else {
            this.props.updateErrorMessage("No row selected");
        }
    }

    onSelectedProdChange(event) {
        let index = event.nativeEvent.target.selectedIndex;
        this.setState({ selectedProdIndex: index});
    }

    onSelectedRowChange(event) {
        let index = event.nativeEvent.target.selectedIndex;
        this.setState({ selectedRowIndex: index});
    }

    onFormSubmit() {
        if (this.validateForm()) {
            this.confirmReturn();
        }
    }

    onPrintClick() {
        if (this.state.pdf !== "") {
            let pdfBlob = this.base64toBlob(this.state.pdf, 'application/pdf');
            this.openPdf(pdfBlob);
        }
        else {
            this.props.updateErrorMessage("No confirmed returns");
        }
    }

    openPdf(blob) {
        let win = window.open('', 'name', 'height=800, width=800'),
            iframe = document.createElement('iframe'),
            title = document.createElement('title'),
            fileUrl = URL.createObjectURL(blob);

        title.appendChild(document.createTextNode('Return waybill'));

        iframe.src = fileUrl;
        iframe.width = '100%';
        iframe.height = '100%';
        iframe.style.border = 'none';

        win.document.head.appendChild(title);
        win.document.body.appendChild(iframe);
        win.document.body.style.margin = 0;
    }

    validateForm() {
        if(this.calculateTotalAmount() === 0) {
            this.props.updateErrorMessage("No items scanned/added");
            return false;
        }
        if(this.state.euroPalletsAmount !== "" && !this.checkIfNumeric(this.state.euroPalletsAmount)) {
            this.props.updateErrorMessage("Invalid euro pallets amount");
            return false;
        }
        return true;

    }

    render() {
        const totalAmount = "Total amount: " + this.calculateTotalAmount();

        return (this.state.isLoading ?
                <div className={"loader"}>
                    <h3>Please wait, loading...</h3>
                    <LoadingIndicator />
                </div> :
            <div>
                <Form>
                    <FormGroup>
                        <Input value={this.state.scannedCode}
                               onChange={e => this.setState({ scannedCode: e.target.value })}
                               type="text" name="scanNumber" id="scanNumber" placeholder="Scan serial number"/>
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={this.handleEnterProdBtnClick}>Enter product</Button>
                        <Button onClick={this.handleRemoveProdBtnClick}>Remove product</Button>
                        </FormGroup>
                    <FormGroup>
                        <CustomInput className="importBtn" type="file" id="importBtn" name="customFile"
                                     label="Import from file"
                                     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                     onChange={this.handleFileSelect}/>
                    </FormGroup>
                    <FormGroup>
                        <Input bsSize="sm"  type="select" name="selectMulti" id="exampleSelectMulti" multiple onChange={this.onSelectedProdChange}>
                            {this.generateScannedItems()}
                        </Input>
                    </FormGroup>
                    <Row id={"chooseProdRow"} form>
                        <FormGroup>
                            <Input value={this.state.chosenItem}
                                   onChange={e => this.setState({ chosenItem: e.target.value, unit: this.getUnit(e.target.value)})}
                                   type="select" name="chooseProduct" id="chooseProduct">
                                <option value="" defaultValue disabled hidden>Choose product</option>
                                {this.generateDropdownProducts()}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Input value={this.state.amount}
                                   onChange={e => this.setState({ amount: e.target.value })}
                                   type="text" name="enterAmount" id="enterAmount" placeholder="Enter amount" />
                        </FormGroup>
                        <FormGroup>
                            <Input value={this.state.unit}
                                   type="text" name="unit" id="unit" placeholder="Unit" readOnly/>
                        </FormGroup>
                    </Row>
                    <FormGroup>
                        <Button onClick={this.handleAddRowBtnClick}>Add row</Button>
                        <Button onClick={this.handleRemoveRowBtnClick}>Remove row</Button>
                    </FormGroup>
                    <FormGroup>
                        <Input bsSize="sm" type="select" name="selectMulti" id="exampleSelectMulti" multiple onChange={this.onSelectedRowChange}>
                            {this.generateRows()}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label id={"totalAmountLabel"} for={"totalAmount"}>{totalAmount}</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input bsSize="sm"
                               value={this.state.notes}
                               onChange={e => this.setState({ notes: e.target.value })}
                               type="textarea" name="comments" id="comments" placeholder=
                            {`Add comments \n\nNB! If something is missing/broken, please, for each STB set enter STB serial number and missing/broken parts
                        `}/>
                    </FormGroup>
                    <FormGroup>
                        <Input value={this.state.euroPalletsAmount}
                               onChange={e => this.setState({ euroPalletsAmount: e.target.value })}
                               type="text" name="euroPalletsAmount" className="smallInput" placeholder="Euro pallets amount"/>
                    </FormGroup>
                </Form>
                <div className="actionButtons">
                    <BackBtn/>
                    <Button onClick={this.onFormSubmit}>Confirm return</Button> {" "}
                    <Button onClick={this.onPrintClick}>Print return waybill</Button>
                </div>
            </div>
        );
    }
}

export default ReturnProducts;